import React from "react";
import { convertUtcToLocalTime } from "./DateFormating";
interface StatusProps {
  isActive: boolean;
  isActiveLabel?: string;
  isInactiveLabel?: string;
  ts?: Date | string;
  tsLabel?: string;
}

const Status = ({
  isActive = false,
  isActiveLabel,
  isInactiveLabel,
  ts,
  tsLabel,
}: StatusProps) => {
  return (
    <div className="flex items-center space-x-4 w-full max-w-md my-3">
      <div className="flex items-center">
        {isActive ? (
          <div
            className="ml-2 h-2 w-2 rounded-full bg-green-600 animate-pulse shadow-lg"
            style={{
              boxShadow: "0 0 0 0 #69ffa8",
              animation: "pulse 1.5s infinite ease-in-out",
            }}
          ></div>
        ) : (
          <div
            className="ml-2 h-2 w-2 rounded-full bg-red-400 shadow-lg"
            style={{
              boxShadow: "0 0 0 0 #69ffa8",
            }}
          ></div>
        )}
        {isActiveLabel && isInactiveLabel && (
          <p className="ml-2">{isActive ? isActiveLabel : isInactiveLabel}</p>
        )}
      </div>
      <div className="leading-none">
        {ts && <p className="text-xl text">{convertUtcToLocalTime(ts)}</p>}
        {ts && tsLabel && <p className="ml-0.5">{tsLabel}</p>}
      </div>
    </div>
  );
};

export default Status;
