import React, { useState, useEffect, useRef } from "react";
import useGetLanguage from "./useGetLanguage";
import { LanguageText } from "../dictionary/UtilsText";

interface DateRangePickerProps {
  initialStartDate: Date;
  initialEndDate: Date;
  onChange: (startDate: Date | null, endDate: Date | null) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  initialStartDate,
  initialEndDate,
  onChange,
}) => {
  const language: LanguageText = useGetLanguage({
    fileName: "UtilsText",
  });
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date>(initialEndDate);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const selectOptions = [
    language?.components?.dateRangePicker?.selectOptions?.thisMonth || "This Month",
    language?.components?.dateRangePicker?.selectOptions?.lastMonth || "Last Month",
  ];

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  useEffect(() => {
    validateDates();
  }, [startDate, endDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validateDates = () => {
    if (startDate && endDate && startDate > endDate) {
      setErrorMessage(
        language?.components?.dateRangePicker?.errorMsg ||
          "Start date cannot be after end date"
      );
    } else {
      setErrorMessage(null);
    }
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newStartDate = value ? new Date(value) : new Date();
    setStartDate(newStartDate);
    onChange(newStartDate, endDate);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newEndDate = value ? new Date(value) : new Date();
    setEndDate(newEndDate);
    onChange(startDate, newEndDate);
  };

  const setDateRange = (range: string) => {
    Date();
    const today = new Date();
    let start: Date, end: Date;

    switch (range) {
      // case "today":
      //   start = end = today;
      //   break;
      // case "thisWeek":
      //   start = new Date(today.setDate(today.getDate() - today.getDay()));
      //   end = new Date(today.setDate(today.getDate() - today.getDay() + 6));
      //   break;
      case selectOptions[0]: // Case for this month
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case selectOptions[1]: // Case for last month
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      // case "thisYear":
      //   start = new Date(today.getFullYear(), 0, 1);
      //   end = new Date(today.getFullYear(), 11, 31);
      //   break;
      default:
        return;
    }

    setStartDate(start);
    setEndDate(end);
    onChange(start, end);
    setIsDropdownOpen(false);
  };

  return (
    <div className="flex flex-col items-center space-y-4 mt-6 mb-6 p-4 bg-white rounded-lg shadow-md">
      <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 w-full">
        <div className="flex flex-col relative w-full m-2 sm:w-40">
          <input
            type="date"
            max={endDate ? endDate.toISOString().split("T")[0] : undefined}
            value={startDate ? startDate.toISOString().split("T")[0] : ""}
            onChange={handleStartDateChange}
            className="px-2 py-1 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          />
          <label className="absolute left-2 top-1 text-xs font-medium text-gray-500 transform -translate-y-6 origin-[0] scale-75">
            {language?.components?.dateRangePicker?.from || "From"}
          </label>
        </div>
        <div className="flex flex-col relative w-full sm:w-40">
          <input
            type="date"
            min={startDate ? startDate.toISOString().split("T")[0] : undefined}
            value={endDate ? endDate.toISOString().split("T")[0] : ""}
            onChange={handleEndDateChange}
            className="px-2 py-1 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          />
          <label className="absolute left-2 top-1 text-xs font-medium text-gray-500 transform -translate-y-6 origin-[0] scale-75">
            {language?.components?.dateRangePicker?.to || "To"}
          </label>
        </div>
        <div className="relative" ref={dropdownRef}>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setIsDropdownOpen(!isDropdownOpen);
            }}
            className="px-3 py-1 bg-gray-200 text-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            ▼
          </button>
          {isDropdownOpen && (
            <div
              className="absolute z-50 mt-1 w-48 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto
                        sm:right-0 left-1/2 sm:left-auto transform sm:translate-x-0 translate-x-[-50%]"
            >
              <ul className="py-1">
                {selectOptions.map((range) => (
                  <li
                    key={range}
                    onClick={() => setDateRange(range)}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-left w-full"
                  >
                    {range}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {errorMessage && <div className="text-red-500 text-sm mt-2">{errorMessage}</div>}
    </div>
  );
};

export default DateRangePicker;
