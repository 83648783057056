import _ from "lodash";
import { useState, useEffect } from "react";
import AnimateHeight from "react-animate-height";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { useAppSelector } from "../../app/hooks";
import { FormInput } from "../../components/FormInput";
import InputModal from "../../components/modals/InputModal";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/DeviceText";
import { selectData, DataType } from "../../features/data/dataSlice";
import { selectLanguage } from "../../features/user/userSlice";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { Device } from "../../features/devices/deviceSlice";
import { UplinkData } from "../../services/dataAPI";
import DateRangePicker from "../../utils/DateRangePicker";

type LineListEntry = {
  name: string;
  pheno: string | number;
  config: string;
  checked: boolean;
};

export type DownloadModalProps = {
  setInternalModal: (value: boolean) => void;
  lineList: LineListEntry[];
  setLineList: (value: LineListEntry[]) => void;
  device: { name: string; eui: string };
  getDeviceData: any;
};

function DownloadModal({
  setInternalModal,
  lineList,
  setLineList,
  device,
  getDeviceData,
}: DownloadModalProps) {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  type filteredType = {
    ts: string | Date;
    [key: string]: string | Date | number;
  }[];

  const [downloadFormat, setDownloadFormat] = useState("JSON");
  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD HH:mm:ss");
  const [dataTitle, setDataTitle] = useState("custom");
  const [seed, setSeed] = useState(0);
  const [advancedIsOpen, setAdvancedIsOpen] = useState(false);

  var nodeData = useAppSelector(selectData);
  const dateFormatOptions = [
    { optionValue: "YYYY-MM-DD HH:mm:ss", optionName: "YYYY-MM-DD HH:mm:ss" },
    { optionValue: "YYYY-MM-DDTHH:mm:ss.msZ", optionName: "YYYY-MM-DDTHH:mm:ss.msZ" },
  ];

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const handleDateRangeChange = (newStartDate: Date | null, newEndDate: Date | null) => {
    if (newStartDate && newEndDate) {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };
  const formatDate = (date: Date): string => {
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const filterDataByDateRange = (
    data: filteredType,
    startDate: Date,
    endDate: Date
  ): { data: filteredType; startDate: Date; endDate: Date } => {
    // Helper function to parse the timestamp string into a Date object
    const parseDate = (ts: string | Date): Date => {
      if (typeof ts === "string") {
        const [day, month, year, hours, minutes, seconds] = ts
          .split(/[/\s:]/)
          .map(Number);
        return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds)); // Create Date object in UTC
      }
      return new Date(ts); // If ts is already a Date object, just return it
    };

    // Convert startDate and endDate to UTC by setting their time zone to UTC (as they are in GMT)
    const utcStartDate = new Date(
      Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate(),
        0,
        0,
        0
      )
    );
    const utcEndDate = new Date(
      Date.UTC(
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate(),
        23,
        59,
        59,
        999
      )
    );

    const filteredData: filteredType = data.filter((item) => {
      const itemDate = parseDate(item.ts);

      // Check if the itemDate is within the start and end date range in UTC
      return itemDate >= utcStartDate && itemDate <= utcEndDate;
    });
    return {
      data: filteredData,
      startDate: utcStartDate,
      endDate: utcEndDate,
    };
  };

  const dataTitleOptions = [
    {
      optionValue: "custom",
      optionName: language.editDevice.downloadModal.formDefaults.dataTitles.custom,
    },
    {
      optionValue: "config",
      optionName: language.editDevice.downloadModal.formDefaults.dataTitles.config,
    },
  ];

  const FormatSelection = (
    <>
      <div>
        <input
          key={"JSON"}
          type="radio"
          id="json"
          name={"JSON"}
          value={"json"}
          checked={downloadFormat === "JSON"}
          onChange={() => {
            setDownloadFormat("JSON");
          }}
        />
        <label className="text-black font-semibold px-2">JSON</label>
        <input
          key={"CSV"}
          type="radio"
          id="csv"
          name={"CSV"}
          disabled={false}
          value="csv"
          checked={downloadFormat === "CSV"}
          onChange={() => {
            setDownloadFormat("CSV");
          }}
        />
        <label className="text-black font-semibold px-2">CSV</label>
      </div>
    </>
  );

  const LineChecklist = (
    <>
      <div>
        {lineList.map((a: any, i: number) => (
          <div
            key={i}
            onClick={() => {
              var tempLineList = lineList;
              tempLineList[i] = {
                name: a.name,
                pheno: a.pheno,
                config: a.config,
                checked: !a.checked,
              };
              setLineList(tempLineList);
              setSeed(Math.random());
            }}
          >
            <input
              type="checkbox"
              id="w"
              name={"w"}
              value={"w"}
              checked={a.checked}
              onChange={() => {}}
            />
            <label className="text-black font-semibold px-2">{a.name}</label>
          </div>
        ))}
      </div>
    </>
  );

  const AdvancedOptions = (
    <>
      <div>
        <div className="w-full">
          <button
            className="w-full text-left"
            type="button"
            onClick={() => setAdvancedIsOpen(!advancedIsOpen)}
          >
            <div className="bg-gray-200 rounded-lg w-fit">
              <div className="text-black font-semibold px-2 inline-flex space-x-1">
                <div>{language.editDevice.downloadModal.labels.advanced}</div>
                <div className="pt-[3px]">
                  {advancedIsOpen ? (
                    <MdKeyboardArrowDown size={18} />
                  ) : (
                    <MdKeyboardArrowRight size={18} />
                  )}
                </div>
              </div>
            </div>
          </button>
          <AnimateHeight
            duration={200}
            height={advancedIsOpen ? "auto" : 0}
            className="rounded-lg ml-1"
          >
            <div className="mt-1 mb-2 text-md text-gray-600 flex justify-between w-full">
              <div className="w-[45%]">
                <FormInput
                  label={language.editDevice.downloadModal.labels.dateFormat}
                  htmlFor="dateformat"
                  type="select"
                  data={dateFormatOptions}
                  value={dateFormat}
                  onChange={(event) => {
                    setDateFormat(event.target.value);
                  }}
                />
              </div>
              <div className="w-[45%]">
                <FormInput
                  label={language.editDevice.downloadModal.labels.dataTitles}
                  htmlFor="datatitles"
                  type="select"
                  data={dataTitleOptions}
                  value={dataTitle}
                  onChange={(event) => {
                    setDataTitle(event.target.value);
                  }}
                />
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </>
  );

  return (
    <InputModal
      onSubmit={async (e) => {
        e.preventDefault();
        setInternalModal(false);
        Download();
      }}
      onCancel={() => setInternalModal(false)}
      Title={
        language.editDevice.downloadModal.title.phrase1 +
        " " +
        (device.name ?? "Device") +
        " " +
        language.editDevice.downloadModal.title.phrase2
      }
      Loading={false}
      Body={
        <>
          {FormatSelection}
          <hr className="mt-1" />
          {LineChecklist}
          <hr className="my-1" />
          <DateRangePicker
            initialStartDate={startDate}
            initialEndDate={endDate}
            onChange={handleDateRangeChange}
          />
          <hr className="my-1" />
          {AdvancedOptions}
        </>
      }
      Form={[]}
      AcceptButton={language.editDevice.downloadModal.buttons.download}
      CancelButton={language.editDevice.downloadModal.buttons.cancel}
    />
  );

  async function Download() {
    var data: UplinkData[] = await GetData();
    var filtered: filteredType = dataFilter(data);
    const {
      data: dataByDateRange,
      startDate: utcStartDate,
      endDate: utcEndDate,
    } = filterDataByDateRange(filtered, startDate, endDate);
    if (downloadFormat === "JSON") {
      downloadJSON(dataByDateRange, startDate, endDate);
    } else if (downloadFormat === "CSV") {
      downloadCSV(dataByDateRange, startDate, endDate);
    }
  }

  async function GetData() {
    var data: UplinkData[];
    if (nodeData.data[device.eui as keyof DataType] === undefined) {
      data = (await getDeviceData({ eui: device.eui }, true)).data;
    } else {
      data = nodeData.data[device.eui as keyof DataType];
    }
    return data;
  }

  function dataFilter(
    data: UplinkData[]
  ): { ts: string | Date; [key: string]: string | Date | number }[] {
    return _.map(data, (value) => {
      return {
        ts:
          dateFormat === "YYYY-MM-DDTHH:mm:ss.msZ"
            ? value["ts"]
            : new Date(value["ts"]).toLocaleString("en-GB").replace(",", ""),
        ...Object.values(
          _.pick(
            value["data_points"],
            _.filter(lineList, function (o) {
              return o.checked === true;
            }).map((line) => line.config)
          )
        ).reduce((acc, val) => {
          return {
            ...acc,
            ..._.pickBy(
              dataTitle === "custom"
                ? _.mapKeys(
                    val,
                    (v, k) =>
                      _.filter(lineList, function (o) {
                        return o.pheno === k && o.checked === true;
                      })[0]?.name
                  )
                : _.mapKeys(
                    val,
                    (v, k) =>
                      _.filter(lineList, function (o) {
                        return o.pheno === k && o.checked === true;
                      })[0]?.pheno
                  ),
              (v, k) => k !== "undefined"
            ),
          };
        }, {}),
      };
    });
  }

  function downloadJSON(filtered: filteredType, startDate: Date, endDate: Date) {
    const link = document.createElement("a");
    const fileData = JSON.stringify(filtered);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${device.name}_${formatDate(startDate)}_${formatDate(endDate)}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function downloadCSV(filtered: filteredType, startDate: Date, endDate: Date) {
    const link = document.createElement("a");
    const fileData = toCSV(filtered);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${device.name}_${formatDate(startDate)}_${formatDate(endDate)}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function toCSV(filtered: filteredType) {
    let csvContent = [
      [
        "ts",
        dataTitle === "custom"
          ? lineList
              .filter((line: LineListEntry) => line.checked === true)
              .map((line: LineListEntry) => line.name)
              .join(",")
          : lineList
              .filter((line: LineListEntry) => line.checked === true)
              .map((line: LineListEntry) => line.pheno)
              .join(","),
      ],
      filtered
        .map((a: { [key: string]: string | number | Date }) => {
          return [
            a.ts,
            [
              lineList
                .filter((line: LineListEntry) => line.checked === true)
                .map((line: LineListEntry) =>
                  dataTitle === "custom" ? line.name : line.pheno
                ),
            ].map((c: (string | number)[]) => c.map((d: string | number) => a[d] ?? "")),
          ];
        })
        .join("\n"),
    ]
      .map((e) => e)
      .join("\n");
    return csvContent;
  }
}

export default DownloadModal;
