import { Navigate, Outlet, useLocation } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Claim from "./routes/device/Claim";
import Login from "./routes/authentication/Login";
import CreateGroup from "./routes/groups/CreateGroup";
import JoinGroup from "./routes/groups/JoinGroup";
import GroupIndex from "./routes/groups/GroupIndex";
import DeviceIndex from "./routes/device/DeviceIndex";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectAuth, login } from "./features/auth/authSlice";
import Home from "./routes/Home";
import Subscription from "./routes/groups/Subscription";
import SplashScreen from "./components/SplashScreen";
import VerifyEmail from "./routes/authentication/VerifyEmail";
import { useEffect } from "react";
import { User } from "./features/user/userSlice";
import { useGetUserInfoQuery } from "./services/userAPI";
import DashboardIndex from "./routes/dashboard/DashboardIndex";
import ViewDashboard from "./routes/dashboard/ViewDashboard";
import DeviceTable from "./routes/device/DeviceTable";
import GroupTable from "./routes/groups/GroupTable";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import CreateDashboard from "./routes/dashboard/CreateDashboard";
import DashboardTable from "./routes/dashboard/DashboardTable";
import ResetPassword from "./routes/authentication/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GroupInvite from "./routes/groups/GroupInvite";
import UserIndex from "./routes/user/UserIndex";
import Settings from "./routes/user/Settings";
import P404 from "./routes/404";
import Info from "./routes/Info";
import Help from "./routes/Help";
import GroupEditUser from "./routes/groups/GroupEditUser";
import GroupMemberTable from "./routes/groups/GroupMemberTable";
import GroupDeviceTable from "./routes/groups/GroupDeviceTable";
import GroupDashboardTable from "./routes/groups/GroupDashboardTable";
import GroupNotificationsIndex from "./routes/groups/GroupNotificationsIndex";
import CreateGroupNotification from "./routes/groups/CreateGroupNotification";
import UpdateGroupNotification from "./routes/groups/UpdateGroupNotification";
import { handleError } from "./utils/ErrorHandling";
import LinkVerifyEmail from "./routes/authentication/LinkVerifyEmail";
import VerifyNewEmail from "./routes/authentication/VerifyNewEmail";
import InitialCreateGroup from "./routes/groups/InitialCreateGroup";
import ViewEditDevice from "./routes/device/ViewEditDevice";
import EditDeviceParameters from "./routes/device/EditDeviceParameters";
import EditDeviceGateways from "./routes/device/EditDeviceGateways";
import DeviceOfflineNotification from "./routes/device/DeviceOfflineNotification";

import GroupEditInvite from "./routes/groups/GroupEditInvite";
import KeepOnSubscriptionDowngrade from "./routes/groups/KeepOnSubscriptionDowngrade";
import EditDeviceIndex from "./routes/device/EditDeviceIndex";
import EditGroupIndex from "./routes/groups/EditGroupIndex";
import LifeRings from "./routes/LifeRings";
import SubscriptionConfirmation from "./routes/authentication/SubscriptionConfirmation";
import GroupViewSubscription from "./routes/groups/GroupViewSubscription";
import TermsAndConditions from "./routes/TermsAndConditions";
import DeviceExcludeData from "./routes/device/DeviceExcludeData";
import GroupTransfer from "./routes/groups/GroupTransfer";
import EditDeviceAdvanced from "./routes/device/EditDeviceAdvanced";
import Demo from "./routes/Demo";
import DemoIndex from "./routes/DemoIndex";
import Demo2 from "./routes/Demo2";

var oneTime = true;
export default function App() {
  //gets the token and if the users email has been verified from the store
  let { token, verifiedEmail } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  //if the token exists, the get user info query is called
  /*console.log("token: " + token + " skip");
  const { error: getUserInfoError } = useGetUserInfoQuery("", {
    skip: token !== null ? false : true,
  });*/
  //on first load, if the token is not loaded but exists in local storage, the login action is dispatched with the token if it exists
  if (!token && oneTime) {
    //dispatch(login({ token: "TOKEN", verifiedEmail: true, rememberMe: true }));
    if (localStorage.getItem("auth") !== null) {
      dispatch(login(JSON.parse(localStorage.getItem("auth") as string)));
      token = JSON.parse(localStorage.getItem("auth") as string).token;
      verifiedEmail = JSON.parse(localStorage.getItem("auth") as string).verifiedEmail;
    } else if (sessionStorage.getItem("auth") !== null) {
      dispatch(login(JSON.parse(sessionStorage.getItem("auth") as string)));
      token = JSON.parse(sessionStorage.getItem("auth") as string).token;
      verifiedEmail = JSON.parse(sessionStorage.getItem("auth") as string).verifiedEmail;
    }
    oneTime = false;
  }

  const {
    error: getUserInfoError,
    isError: isGetUserInfoError,
    isLoading: isGetUserLoading,
    isFetching: isGetUserFetching,
    data: userData,
  } = useGetUserInfoQuery(token ? "" : skipToken);

  useEffect(() => {
    //If there is an error when getting user info, the error is handled appropriately
    if (isGetUserInfoError) {
      handleError(getUserInfoError);
    }
  }, [getUserInfoError, isGetUserInfoError]);

  type AuthProps = {
    user_access_token: string | null;
  };

  const AuthLayout = ({ user_access_token }: AuthProps) => {
    const location = useLocation();
    console.log("AuthLayout location: " + location.pathname + location.search);

    if (
      !location.pathname?.startsWith("/verifyemail") &&
      !location.pathname?.startsWith("/verifyemaillink")
    ) {
      localStorage.setItem("redirectURL", location.pathname + location.search);
    }
    return user_access_token ? (
      <Outlet />
    ) : (
      <>
        <Navigate to="/login" replace />
      </>
    );
  };

  type EmailVerifiedProps = {
    user_verified_email: boolean | null;
  };

  //If the user is trying to access a page that requires authentication and they have not had their email verified, they are redirected to the verify email page
  const IsEmailVerified = ({ user_verified_email }: EmailVerifiedProps) => {
    const location = useLocation();
    console.log("IsEmailVerified location: " + location.pathname + location.search);
    if (
      localStorage.getItem("redirectURL") === null ||
      localStorage.getItem("redirectURL") === undefined ||
      localStorage.getItem("redirectURL") === "/"
    ) {
      localStorage.setItem("redirectURL", location.pathname + location.search);
    }
    return user_verified_email ? (
      <Outlet />
    ) : (
      <>
        <Navigate to="/verifyemail" replace />
      </>
    );
  };

  const IsPartOfGroup = ({
    user,
    isGetUserInfoLoading,
  }: {
    user: User | undefined | null;
    isGetUserInfoLoading: boolean;
  }) => {
    const location = useLocation();
    console.log("IsPartOfGroup location: " + location.pathname + location.search);
    if (
      localStorage.getItem("redirectURL") === null ||
      localStorage.getItem("redirectURL") === undefined ||
      localStorage.getItem("redirectURL") === "/"
    ) {
      localStorage.setItem("redirectURL", location.pathname + location.search);
    }
    while (user === undefined || user === null || isGetUserInfoLoading) {
      return <SplashScreen />;
    }
    return Object.keys(user.groups).length > 0 ? (
      <Outlet />
    ) : (
      <>
        <Navigate to="/creategroup" replace />
      </>
    );
  };

  //Defines the routes for the application
  return (
    <>
      <ToastContainer
        autoClose={3000}
        position="top-right"
        newestOnTop={false}
        closeOnClick
      />
      <BrowserRouter>
        <Routes>
          <Route>
            {/*Paths accessible to non-logged in users*/}
            <Route path="*" element={<Navigate to="/404" replace />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="404" element={<P404 />} />
            <Route path="login" element={<Login />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route path="verifyemaillink" element={<LinkVerifyEmail />} />
            <Route path="subscription/confirm" element={<SubscriptionConfirmation />} />
            <Route element={<AuthLayout user_access_token={token} />}>
              {/*Paths only accessible to logged in users*/}
              <Route path="verifyemail" element={<VerifyEmail />} />
              <Route element={<IsEmailVerified user_verified_email={verifiedEmail} />}>
                <Route path="joingroup" element={<JoinGroup />} />
                <Route path="creategroup" element={<InitialCreateGroup />} />
                <Route
                  element={
                    <IsPartOfGroup
                      user={userData}
                      isGetUserInfoLoading={
                        isGetUserLoading ||
                        (isGetUserFetching && Object.keys(userData.groups).length === 0)
                      }
                    />
                  }
                >
                  {/*Paths only accessible to users with a verified email*/}

                  <Route path="verifynewemail" element={<VerifyNewEmail />} />
                  <Route path="" element={<Home />}>
                    <Route index element={<Info />} />
                    {/*Paths related to devices*/}
                    <Route path="device" element={<DeviceIndex />}>
                      <Route index element={<DeviceTable />} />
                      <Route path="claim" element={<Claim />} />
                      <Route path=":deviceid" element={<EditDeviceIndex />}>
                        <Route index element={<ViewEditDevice />} />
                        <Route path="parameters" element={<EditDeviceParameters />} />
                        <Route path="gateways" element={<EditDeviceGateways />} />
                        <Route path="exclude" element={<DeviceExcludeData />} />
                        <Route path="downlink" element={<EditDeviceAdvanced />} />
                        <Route path="offline" element={<DeviceOfflineNotification />} />
                      </Route>
                    </Route>
                    {/*Paths related to groups*/}
                    <Route path="group" element={<GroupIndex />}>
                      <Route index element={<GroupTable />} />
                      <Route path="create" element={<CreateGroup />} />
                      <Route path="transfer" element={<GroupTransfer />} />
                      <Route path=":groupid" element={<GroupIndex />}>
                        <Route path="" element={<EditGroupIndex />}>
                          <Route path="invite" element={<GroupInvite />} />
                          <Route path="members" element={<GroupMemberTable />}>
                            <Route path=":memberid">
                              <Route path="edit" element={<GroupEditUser />} />
                              <Route path="editinvite" element={<GroupEditInvite />} />
                            </Route>
                          </Route>
                          <Route path="devices" element={<GroupDeviceTable />} />
                          <Route path="dashboards" element={<GroupDashboardTable />} />
                          <Route
                            path="subscription"
                            element={<GroupViewSubscription />}
                          />
                          <Route
                            path="notifications"
                            element={<GroupNotificationsIndex />}
                          />
                          <Route
                            path="notifications/create"
                            element={<CreateGroupNotification />}
                          />
                          <Route
                            path="notifications/:notificationid/update"
                            element={<UpdateGroupNotification />}
                          />
                        </Route>
                        <Route path="subscription/start" element={<Subscription />} />
                        <Route path="limit" element={<KeepOnSubscriptionDowngrade />} />
                      </Route>
                    </Route>
                    {/*Paths related to dashboards*/}
                    <Route path="dashboard" element={<DashboardIndex />}>
                      <Route index element={<DashboardTable />} />
                      <Route path=":dashboardid" element={<ViewDashboard />} />
                      <Route path="create" element={<CreateDashboard />} />
                    </Route>
                    {/*Paths related to users*/}
                    <Route path="user" element={<UserIndex />}>
                      <Route path="settings" element={<Settings />} />
                    </Route>
                    <Route path="help" element={<Help />} />
                    <Route path="liferings" element={<LifeRings />} />
                    <Route path="demo" element={<Demo2 />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
