export type LanguageText = {
  table: {
    searchAllColumns: string;
    goToPage: string;
    show: string;
  };
  components: {
    dateRangePicker: {
      from: string;
      to: string;
      errorMsg: string;
      selectOptions: {
        thisMonth: string;
        lastMonth: string;
      };
    };
  };
};
export const English: LanguageText = {
  table: {
    searchAllColumns: "Search All Columns",
    goToPage: "Go to Page",
    show: "Show",
  },
  components: {
    dateRangePicker: {
      from: "From",
      to: "To",
      errorMsg: "Start date cannot be after end date",
      selectOptions: {
        thisMonth: "This month",
        lastMonth: "Last month",
      },
    },
  },
};
export const French: LanguageText = {
  table: {
    searchAllColumns: "Rechercher dans toutes les colonnes",
    goToPage: "Aller à la page",
    show: "Afficher",
  },
  components: {
    dateRangePicker: {
      from: "De",
      to: "À",
      errorMsg: "La date de début ne peut pas être après la date de fin",
      selectOptions: {
        thisMonth: "Ce mois-ci",
        lastMonth: "Le mois dernier",
      },
    },
  },
};
export const German: LanguageText = {
  table: {
    searchAllColumns: "Alle Spalten durchsuchen",
    goToPage: "Weiter zur Seite",
    show: "Anzeigen",
  },
  components: {
    dateRangePicker: {
      from: "Von",
      to: "Bis",
      errorMsg: "Das Startdatum darf nicht nach dem Enddatum liegen",
      selectOptions: {
        thisMonth: "Diesen Monat",
        lastMonth: "Letzten Monat",
      },
    },
  },
};
export const Dutch: LanguageText = {
  table: {
    searchAllColumns: "Doorzoek alle kolommen",
    goToPage: "Ga naar de pagina",
    show: "Toon",
  },
  components: {
    dateRangePicker: {
      from: "Van",
      to: "Tot",
      errorMsg: "De startdatum mag niet na de einddatum liggen",
      selectOptions: {
        thisMonth: "Deze maand",
        lastMonth: "Vorige maand",
      },
    },
  },
};
export const Spanish: LanguageText = {
  table: {
    searchAllColumns: "Buscar en todas las columnas",
    goToPage: "Ir a la página",
    show: "Mostrar",
  },
  components: {
    dateRangePicker: {
      from: "Desde",
      to: "Hasta",
      errorMsg: "La fecha de inicio no puede ser posterior a la fecha de finalización",
      selectOptions: {
        thisMonth: "Este mes",
        lastMonth: "El mes pasado",
      },
    },
  },
};
export const Italian: LanguageText = {
  table: {
    searchAllColumns: "Cerca in tutte le colonne",
    goToPage: "Vai alla pagina",
    show: "Mostra",
  },
  components: {
    dateRangePicker: {
      from: "Da",
      to: "A",
      errorMsg: "La data di inizio non può essere successiva alla data di fine",
      selectOptions: {
        thisMonth: "Questo mese",
        lastMonth: "Il mese scorso",
      },
    },
  },
};
export const Romanian: LanguageText = {
  table: {
    searchAllColumns: "Căutați toate coloanele",
    goToPage: "Mergi la pagina",
    show: "Arată",
  },
  components: {
    dateRangePicker: {
      from: "De la",
      to: "Până la",
      errorMsg: "Data de început nu poate fi după data de sfârșit",
      selectOptions: {
        thisMonth: "Luna aceasta",
        lastMonth: "Luna trecută",
      },
    },
  },
};
export const Portuguese: LanguageText = {
  table: {
    searchAllColumns: "Pesquisar todas as colunas",
    goToPage: "Ir para a página",
    show: "Mostrar",
  },
  components: {
    dateRangePicker: {
      from: "De",
      to: "Até",
      errorMsg: "A data de início não pode ser após a data de término",
      selectOptions: {
        thisMonth: "Este mês",
        lastMonth: "Mês passado",
      },
    },
  },
};
