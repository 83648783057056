import React, { useState, useEffect, ChangeEvent } from "react";

import { useParams } from "react-router-dom";
import { Device } from "../../features/devices/deviceSlice";
import ToggleSwitch from "../../utils/ToggleSwitch";
import { useGetGroupQuery } from "../../services/groupAPI";
import { mapObjectToArray } from "../../utils/GroupNotificationUtils";
import { useUpdateDeviceSettingsMutation } from "../../services/deviceAPI";
import useGetLanguage from "../../utils/useGetLanguage";
import { LanguageText } from "../../dictionary/DeviceText";
import { useAppSelector } from "../../app/hooks";
import { selectDevices } from "../../features/devices/deviceSlice";
import DeviceNotification from "../../components/Device/DeviceNotification";

const DeviceOfflineNotification = (): JSX.Element => {
  const { deviceid } = useParams();
  const language: LanguageText = useGetLanguage({
    fileName: "DeviceText",
  });

  const device = useAppSelector(selectDevices).devices.find(
    (device) => device.id === deviceid
  );

  return <> {device && <DeviceNotification device={device} />}</>;
};

export default DeviceOfflineNotification;
